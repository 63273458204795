.numbers {
  width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &__block {
    margin-top: 130px;
    width: 450px;

    h1 {
      font-size: 96px;
      font-weight: 700;
    }

    p {
      font-size: 24px;
      font-weight: 500;
    }
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

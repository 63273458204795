.header {
  display: flex;
  width: calc(100vw - 320px);
  align-items: center;
  justify-content: space-between;
  margin: 40px auto 0;

  span {
    svg {
      width: 44px;
      height: 44px;
      fill: $black;
    }
  }

  &__name {
    margin-left: 6%;
    font-size: 40px;
    font-weight: 600;

    &-white {
      color: $white;
    }

    &-black {
      color: $black;
    }
  }

  &__logos {
    display: flex;
  }

  &__fitki-logo,
  &__vntu-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 82px;
    height: 82px;
    background: #212121;
    border-radius: 24px;

    svg {
      display: block;
    }
  }
  &__fitki-logo:hover,
  &__vntu-logo:hover {
    background: darken(#212121, 5%);
  }

  &__fitki-logo {
    margin-right: 24px;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    display: flex;
    width: 1040px;
    align-items: center;
    justify-content: space-between;
    margin: 30px auto 0;

    span {
      svg {
        width: 30px;
        height: 30px;
        fill: $black;
      }
    }

    &__name {
      margin-left: 6%;
      font-size: 28px;
      font-weight: 600;

      &-white {
        color: $white;
      }

      &-black {
        color: $black;
      }
    }

    &__logos {
      display: flex;
    }

    &__fitki-logo,
    &__vntu-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 62px;
      background: #212121;
      border-radius: 16px;

      svg {
        display: block;
        width: 42px;
      }
    }
    &__fitki-logo:hover,
    &__vntu-logo:hover {
      background: darken(#212121, 5%);
    }

    &__fitki-logo {
      margin-right: 24px;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .header {
    display: flex;
    width: calc(90vw);
    align-items: center;
    justify-content: space-between;
    margin: 30px auto 0;

    span {
      svg {
        width: 28px;
        height: 28px;
        fill: $black;
      }
    }

    &__name {
      width: 50%;
      margin-left: 5%;
      margin-right: 2%;
      font-size: 14px;
      font-weight: 600;

      &-white {
        color: $white;
      }

      &-black {
        color: $black;
      }
    }

    &__logos {
      display: flex;
    }

    &__fitki-logo,
    &__vntu-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 52px;
      background: #212121;
      border-radius: 16px;

      svg {
        display: block;
        width: 32px;
      }
    }
    &__fitki-logo:hover,
    &__vntu-logo:hover {
      background: darken(#212121, 5%);
    }

    &__fitki-logo {
      margin-right: 10px;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 376px) and (-webkit-min-device-pixel-ratio: 2) {
  .header {
    display: flex;
    width: calc(90vw);
    align-items: center;
    justify-content: space-between;
    margin: 30px auto 0;

    span {
      svg {
        width: 27px;
        height: 27px;
        fill: $black;
      }
    }

    &__name {
      width: 50%;
      margin-left: 5%;
      margin-right: 2%;
      font-size: 0.7rem;
      font-weight: 600;

      &-white {
        color: $white;
      }

      &-black {
        color: $black;
      }
    }

    &__logos {
      display: flex;
    }

    &__fitki-logo,
    &__vntu-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 52px;
      background: #212121;
      border-radius: 16px;

      svg {
        display: block;
        width: 32px;
      }
    }
    &__fitki-logo:hover,
    &__vntu-logo:hover {
      background: darken(#212121, 5%);
    }

    &__fitki-logo {
      margin-right: 10px;
    }
  }
}

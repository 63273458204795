.specialties {
  &__cards {
    position: relative;
    top: 100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 1600px;
    height: 688px;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;

    a {
      color: $white;
    }
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .specialties {
    &__cards {
      position: relative;
      top: 40px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      width: 1040px;
      height: 460px;
      justify-content: space-between;
      align-items: center;
      align-content: space-between;

      a {
        color: $white;
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .specialties {
    &__cards {
      position: relative;
      top: 40px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
      min-height: 100%;
      justify-content: space-between;
      align-items: center;
      align-content: space-between;

      a {
        color: $white;
      }
    }
  }
}

.categories {
  display: flex;
  width: calc(100vw - 1020px);
  height: 80px;
  margin: 40px auto 0;
  align-items: center;
  border-radius: 100px;
  background-color: #a7acc2;
  justify-content: space-evenly;

  li {
    color: $black;
    width: 1000px;
    border: none;
    border-radius: 100px;
    background-color: #a7acc2;
    font-size: 28px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    transition: all 0.1s ease;
    cursor: pointer;

    &.active {
      background-color: $black;
      color: $white;
    }

    &.active span,
    p {
      color: $white;
    }

    &.active p {
      color: $white;
    }

    span,
    p {
      color: $black;
    }

    p {
      font-size: 18px;
    }
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .categories {
    display: flex;
    width: calc(100vw - 800px);
    height: 60px;
    margin: 30px auto 0;
    align-items: center;
    border-radius: 100px;
    background-color: #a7acc2;
    justify-content: space-evenly;

    li {
      color: $black;

      width: 300px;

      border: none;
      border-radius: 100px;
      background-color: #a7acc2;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      transition: all 0.1s ease;
      cursor: pointer;

      &.active {
        background-color: $black;
        color: $white;
      }

      &.active span,
      p {
        color: $white;
      }

      &.active p {
        color: $white;
      }

      span,
      p {
        color: $black;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .categories {
    display: flex;
    width: 96vw;
    height: 6vh;
    margin: 0 auto;
    align-items: center;
    border-radius: 100px;
    background-color: #a7acc2;
    justify-content: space-evenly;

    li {
      color: $black;
      width: 32vw;
      border: none;
      border-radius: 100px;
      background-color: #a7acc2;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      transition: all 0.1s ease;
      cursor: pointer;

      &.active {
        background-color: $black;
        color: $white;
      }

      &.active span,
      p {
        color: $white;
      }

      &.active p {
        color: $white;
      }

      span,
      p {
        color: $black;
      }

      p {
        font-size: 10px;
      }
    }
  }
}

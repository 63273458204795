.scrolldown {
  // width: 24px;
  // height: 24px;

  //margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chevron {
  color: #000;
  position: absolute;
  width: 28px;
  height: 5px;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: rgba(10, 13, 31, 0.75);
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  font-size: 18px;
  font-weight: 500;
  color: $black;
  margin-top: 150px;
  // opacity: 0.8;
  // animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .chevron {
    color: #000;
    position: absolute;
    width: 22px;
    height: 5px;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
  }

  .text {
    font-size: 14px;
    font-weight: 500;
    color: $black;
    margin-top: -10px;
    // opacity: 0.8;
    // animation: pulse 2s linear alternate infinite;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .chevron {
    color: #000;
    position: absolute;
    width: 22px;
    height: 5px;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
  }

  .text {
    font-size: 14px;
    font-weight: 500;
    color: $black;
    margin-top: -10px;
    // opacity: 0.8;
    // animation: pulse 2s linear alternate infinite;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 376px) and (-webkit-min-device-pixel-ratio: 2) {
  .chevron {
    margin-top: -2rem;

    color: #000;
    position: absolute;
    width: 1rem;
    height: 5px;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
  }

  .text {
    font-size: 0.7rem;
    font-weight: 500;
    color: $black;
    margin-top: -2rem;
    // opacity: 0.8;
    // animation: pulse 2s linear alternate infinite;
  }
}

// .scrolldown {
//   margin-top: 100px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;

//   p {
//     margin-top: 15px;
//     font-size: 18px;
//     font-weight: 500;
//     color: $black;
//   }
// }

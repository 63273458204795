//animation effect

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 1.7s infinite;
  overflow: hidden;
}

.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-20deg);
  // box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}

.skeleton-card {
  background: rgb(221, 222, 236);
  margin: 10px 0;
  width: 475px;
  height: 300px;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  &__titles {
    display: flex;
    flex-direction: column;
    height: 30%;
    margin-bottom: 1rem;
  }

  &__faculties {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    height: 30%;
  }

  &__title {
    background: rgb(191, 193, 206);
    width: 100%;
    height: 20px;
    border-radius: 10px;
    margin-bottom: 1rem;
    &:last-child {
      width: 70%;
    }
  }

  &__faculty {
    background: rgb(191, 193, 206);
    width: 80%;
    height: 15px;
    border-radius: 10px;
    margin-bottom: 1rem;

    &:last-child {
      width: 50%;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      width: 337px;
      height: 78px;
      border-radius: 22px;
      background: rgb(191, 193, 206);
    }

    p {
      width: 78px;
      height: 78px;
      border-radius: 22px;
      background: rgb(191, 193, 206);
    }
  }
}

.skeleton-cards-wrapper {
  position: relative;
}

.skeleton-cards {
  position: relative;
  top: 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  width: 1600px;
  height: 688px;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .skeleton-cards {
    position: relative;
    top: 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 1040px;
    height: 480px;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .skeleton-cards {
    position: relative;
    top: 2px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    min-height: 100%;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .skeleton-card {
    width: 320px;
    height: 200px;
    padding: 14px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px;

    &__titles {
      display: flex;
      flex-direction: column;

      margin-bottom: 0rem;
    }

    &__faculties {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      height: 40%;
    }

    &__title {
      background: rgb(191, 193, 206);
      width: 100%;
      height: 16px;
      border-radius: 10px;
      margin-bottom: 1rem;
      &:last-child {
        width: 70%;
      }
    }

    &__faculty {
      background: rgb(191, 193, 206);
      width: 80%;
      height: 8px;
      border-radius: 10px;
      margin-bottom: 0.5rem;

      &:last-child {
        width: 50%;
      }
    }

    &__button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      div {
        width: 216px;
        height: 60px;
        border-radius: 16px;
        background: rgb(191, 193, 206);
      }

      p {
        width: 60px;
        height: 60px;
        border-radius: 14px;
        background: rgb(191, 193, 206);
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .skeleton-card {
    margin-bottom: 1.5rem;
    width: 90vw;
    height: 28vh;
    padding: 14px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px;

    &__titles {
      display: flex;
      flex-direction: column;

      margin-bottom: 0rem;
    }

    &__faculties {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      height: 40%;
    }

    &__title {
      background: rgb(191, 193, 206);
      width: 100%;
      height: 16px;
      border-radius: 10px;
      margin-bottom: 1rem;
      &:last-child {
        width: 70%;
      }
    }

    &__faculty {
      background: rgb(191, 193, 206);
      width: 80%;
      height: 8px;
      border-radius: 10px;
      margin-bottom: 0.5rem;

      &:last-child {
        width: 50%;
      }
    }

    &__button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      div {
        width: 76%;
        height: 60px;
        border-radius: 16px;
        background: rgb(191, 193, 206);
      }

      p {
        width: 60px;
        height: 60px;
        border-radius: 14px;
        background: rgb(191, 193, 206);
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 376px) and (-webkit-min-device-pixel-ratio: 2) {
  .skeleton-card {
    margin-bottom: 1.5rem;
    width: 90vw;
    height: 30vh;
    padding: 14px;
    display: flex;
    border-radius: 20px;
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px;

    &__titles {
      display: flex;
      flex-direction: column;

      margin-bottom: 0rem;
    }

    &__faculties {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      height: 40%;
    }

    &__title {
      background: rgb(191, 193, 206);
      width: 100%;
      height: 16px;
      border-radius: 10px;
      margin-bottom: 1rem;
      &:last-child {
        width: 70%;
      }
    }

    &__faculty {
      background: rgb(191, 193, 206);
      width: 80%;
      height: 8px;
      border-radius: 10px;
      margin-bottom: 0.5rem;

      &:last-child {
        width: 50%;
      }
    }

    &__button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      div {
        width: 216px;
        height: 60px;
        border-radius: 16px;
        background: rgb(191, 193, 206);
      }

      p {
        width: 60px;
        height: 60px;
        border-radius: 14px;
        background: rgb(191, 193, 206);
      }
    }
  }
}

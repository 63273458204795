.card {
  width: 475px;
  height: 300px;
  border-radius: 26px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $blue;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover {
    transform: translateY(-20px);
  }

  h4 {
    font-size: 30px;
  }

  h5 {
    font-size: 20px;
    font-weight: 500;
    text-align: right;
  }

  &__links {
    display: flex;
    justify-content: space-between;
  }

  &__more {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 337px;
    height: 78px;
    border-radius: 22px;
    border: none;
    background-color: rgba(242, 246, 255, 0.4);
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: rgba(242, 246, 255, 0.5);
    }

    p {
      color: $black;
      font-size: 28px;
      font-weight: 500;
    }
  }

  &__cipher {
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 28px;
    width: 78px;
    height: 78px;
    border-radius: 22px;
  }
}

.blue {
  background: $blue;
}
.green {
  background: $green;
}
.yellow {
  background: $yellow;
}

.red {
  background: $red;
}
.purple {
  background: $purple;
}
.blackText {
  color: $black;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .card {
    width: 320px;
    height: 200px;
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    background: $blue;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      transform: translateY(-20px);
    }

    h4 {
      font-size: 20px;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
      text-align: right;
    }

    &__links {
      display: flex;
      justify-content: space-between;
    }

    &__more {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 216px;
      height: 60px;
      border-radius: 16px;
      border: none;
      background-color: rgba(242, 246, 255, 0.4);
      -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

      &:hover {
        background-color: rgba(242, 246, 255, 0.5);
      }

      p {
        color: $black;
        font-size: 20px;
        font-weight: 500;
      }

      svg {
        width: 34px;
      }
    }

    &__cipher {
      background: #000;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 20px;
      width: 60px;
      height: 60px;
      border-radius: 14px;
    }
  }
  .blue {
    background: $blue;
  }
  .green {
    background: $green;
  }
  .yellow {
    background: $yellow;
  }
  .red {
    background: $red;
  }
  .purple {
    background: $purple;
  }
  .blackText {
    color: $black;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    margin-bottom: 1.5rem;
    width: 90vw;
    height: 28vh;
    padding: 14px;
    display: flex;
    border-radius: 20px;
    background: $blue;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      transform: translateY(0px);
    }

    h4 {
      font-size: 20px;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
      text-align: right;
    }

    &__links {
      display: flex;
      justify-content: space-between;
    }

    &__more {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 76%;
      height: 60px;
      border-radius: 16px;
      border: none;
      background-color: rgba(242, 246, 255, 0.4);
      -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

      &:hover {
        background-color: rgba(242, 246, 255, 0.5);
      }

      p {
        color: $black;
        font-size: 20px;
        font-weight: 500;
      }

      svg {
        width: 34px;
      }
    }

    &__cipher {
      background: #000;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 20px;
      width: 60px;
      height: 60px;
      border-radius: 14px;
    }
  }
  .blue {
    background: $blue;
  }
  .green {
    background: $green;
  }
  .yellow {
    background: $yellow;
  }
  .red {
    background: $red;
  }
  .purple {
    background: $purple;
  }
  .blackText {
    color: $black;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 376px) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    margin-bottom: 1.5rem;
    width: 90vw;
    height: 30vh;
    padding: 14px;
    display: flex;
    border-radius: 20px;
    background: $blue;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      transform: translateY(0px);
    }

    h4 {
      font-size: 1rem;
    }

    h5 {
      font-size: 0.8rem;
      font-weight: 500;
      text-align: right;
    }

    &__links {
      display: flex;
      justify-content: space-between;
    }

    &__more {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 76%;
      height: 60px;
      border-radius: 16px;
      border: none;
      background-color: rgba(242, 246, 255, 0.4);
      -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

      &:hover {
        background-color: rgba(242, 246, 255, 0.5);
      }

      p {
        color: $black;
        font-size: 1rem;
        font-weight: 500;
      }

      svg {
        width: 34px;
      }
    }

    &__cipher {
      background: #000;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 20px;
      width: 60px;
      height: 60px;
      border-radius: 14px;
    }
  }
  .blue {
    background: $blue;
  }
  .green {
    background: $green;
  }
  .yellow {
    background: $yellow;
  }
  .red {
    background: $red;
  }
  .purple {
    background: $purple;
  }
  .blackText {
    color: $black;
  }
}

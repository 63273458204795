@import "fonts";
@import "variables";
@import "libs/normalize";

@import "components/all.scss";

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: transparent;
}

// body {
//   background-color: $background;
//   padding: 50px 0;
//   @media (max-width: 768px) {
//     padding: 20px 0;
//   }
// }

// .wrapper {
//   width: calc(100vw - 100px);
//   height: 100%;
//   background-color: #fff;
//   margin: 0 auto;
//   border-radius: 10px;
//   max-width: 1400px;
//   @media (max-width: 768px) {
//     width: calc(100% - 40px);
//   }
// }

.wrapper {
  color: $white;
  //background-color: $white;
}

.welcome {
  &__title {
    padding-top: 44px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 1500px);
    margin: 0 auto;
  }

  &__container {
    padding-top: 86px;
    display: flex;
    width: calc(100vw - 600px);
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  &__descriptions {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  &__heading {
    margin-bottom: 50px;
  }

  &__links {
    display: flex;
    justify-content: space-between;

    width: calc(100vw - 1100px);
    margin: 0 auto;
  }

  &__specialties,
  &__finder {
    margin-top: 36px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(10, 13, 31, 0.6);
    color: #f2f6ff;
    width: 376px;
    height: 80px;
    border-radius: 25px;
    border: none;

    &:hover {
      background-color: rgba(10, 13, 31, 0.75);
    }

    p {
      font-size: 26px;
      font-weight: 400;
    }
  }
}

.Drawer {
  // transform: translate3d(0, 0, 0);
  // transition-delay: 200ms;
  transition: transform 700ms cubic-bezier(0.75, 0, 0, 1),
    visibility 600ms linear;
  position: fixed;
  top: 0;
  left: 0;
  margin-left: calc(100vw - 98%);
  margin-top: 50px;
  width: 400px;
  height: 850px;
  transform: translateX(0px);
  background: rgba(242, 246, 255, 0.95);
  //transition: transform 0.22s ease-in;
  z-index: 90;
  border-radius: 36px;

  ul {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    height: 86%;

    a {
      &:last-of-type {
        margin-top: auto;
      }
    }
  }

  &__controls {
    position: relative;
    justify-content: flex-end;

    display: flex;
    width: 300px;
    margin: 40px auto 0;

    svg {
      width: 40px;
      height: 40px;
      position: relative;
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 16px auto 0;
    width: 380px;
    height: 82px;
    padding: 10px 40px;
    border-radius: 26px;
    font-size: 24px;
    font-weight: 500;
    // border-bottom: 2px solid rgba(10, 13, 31, 0.5);

    // &:last-of-type {
    //   margin-top: auto;
    // }

    &:hover {
      background: rgba(10, 13, 31, 0.15);
    }
    &:active {
      background: rgba(10, 13, 31, 0.25);
    }

    div {
      display: flex;
    }

    p {
      display: block;
      margin-left: 40px;
      text-align: left;
    }
  }
}

.Drawer.close {
  transform: translateX(-500px);
}

.Backdrop {
  z-index: 50;
  background: rgba(10, 13, 31, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.developing {
  position: relative;
  top: 30vh;
  color: $black;

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 2.4rem;
    }
    svg {
      margin-top: 2rem;
      width: 10rem;
    }
  }

  span {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16rem auto 0;
    background-color: $black;
    color: $white;
    font-size: 1.5rem;
    border: none;
    border-radius: 18px;
    width: 24%;
    height: 4rem;

    &:hover {
      background: lighten($black, 10%);
    }
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .welcome {
    &__title {
      padding-top: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100vw - 1040px);
      margin: 0 auto;

      img {
        width: 70px;
      }

      h1 {
        font-size: 42px;
      }
    }

    &__container {
      height: 340px;
      padding-top: 0;
      display: flex;
      width: calc(100vw - 440px);
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      svg {
        width: 24rem;
      }
    }

    &__descriptions {
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      h2 {
        font-size: 32px;
      }
      h3 {
        font-size: 22px;
      }
    }

    &__heading {
      margin-bottom: 50px;
    }

    &__links {
      display: flex;
      justify-content: space-between;

      width: calc(100vw - 660px);
      margin: 0 auto;
    }

    &__specialties,
    &__finder {
      margin-top: 0px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: rgba(10, 13, 31, 0.6);
      color: #f2f6ff;
      width: 300px;
      height: 66px;
      border-radius: 25px;
      border: none;

      &:hover {
        background-color: rgba(10, 13, 31, 0.75);
      }

      p {
        font-size: 18px;
        font-weight: 400;
      }
      svg {
        width: 30px;
      }
    }
  }

  .Drawer {
    // transform: translate3d(0, 0, 0);
    // transition-delay: 200ms;
    transition: transform 700ms cubic-bezier(0.75, 0, 0, 1),
      visibility 600ms linear;
    position: fixed;
    top: 0;
    left: 0;
    margin-left: calc(100vw - 98%);
    margin-top: 28px;
    width: 280px;
    height: 580px;
    transform: translateX(0px);
    background: rgba(242, 246, 255, 0.95);
    //transition: transform 0.22s ease-in;
    z-index: 90;
    border-radius: 28px;

    ul {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      height: 79%;

      a {
        &:last-of-type {
          margin-top: auto;
        }
      }
    }

    &__controls {
      position: relative;
      justify-content: flex-end;

      display: flex;
      width: 220px;
      margin: 40px auto 0;

      svg {
        width: 26px;
        height: 40px;
        position: relative;
      }
    }

    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 16px auto 0;
      width: 260px;
      height: 58px;
      padding: 10px 30px;
      border-radius: 18px;
      font-size: 16px;
      font-weight: 500;

      svg {
        width: 30px;
      }
      // border-bottom: 2px solid rgba(10, 13, 31, 0.5);

      // &:last-of-type {
      //   margin-top: auto;
      // }

      &:hover {
        background: rgba(10, 13, 31, 0.15);
      }
      &:active {
        background: rgba(10, 13, 31, 0.25);
      }

      div {
        display: flex;
      }

      p {
        display: block;
        margin-left: 20px;
        text-align: left;
      }
    }
  }

  .Drawer.close {
    transform: translateX(-500px);
  }

  .Backdrop {
    z-index: 50;
    background: rgba(10, 13, 31, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .developing {
    position: relative;
    top: 12vh;
    color: $black;

    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-size: 2.2rem;
      }
      svg {
        margin-top: 2rem;
        width: 10rem;
      }
    }

    span {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      font-size: 1.7rem;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 8rem auto 0;
      background-color: $black;
      color: $white;
      font-size: 1.2rem;
      border: none;
      border-radius: 18px;
      width: 24%;
      height: 4rem;

      &:hover {
        background: lighten($black, 10%);
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome {
    &__title {
      padding-top: 1rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(50vw);
      margin: 0 auto;
      padding-bottom: 20px;

      img {
        width: 54px;
      }

      h1 {
        color: $white;
        font-size: 36px;
        margin-right: 10px;
      }
    }

    &__container {
      flex-direction: column;
      height: 400px;
      padding-top: 0;
      display: flex;
      width: calc(90vw);
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      svg {
        width: 20rem;
      }
    }

    &__descriptions {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      h2 {
        color: $black;

        font-size: 24px;
      }
      h3 {
        color: $black;

        font-size: 20px;
      }
    }

    &__heading {
      margin-bottom: 1rem;
    }

    &__links {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 90%;
      margin: 3.5rem 5% 5% 5%;
      position: relative;
    }

    &__specialties,
    &__finder {
      margin-top: 0px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: rgba(10, 13, 31, 0.6);
      color: #f2f6ff;
      width: 90vw;
      height: 4rem;
      border-radius: 25px;
      border: none;
      margin-bottom: 1.5rem;

      &:hover {
        background-color: rgba(10, 13, 31, 0.75);
      }

      p {
        font-size: 18px;
        font-weight: 400;
      }
      svg {
        width: 30px;
      }
    }
  }

  .Drawer {
    // transform: translate3d(0, 0, 0);
    // transition-delay: 200ms;
    transition: transform 700ms cubic-bezier(0.75, 0, 0, 1),
      visibility 600ms linear;
    position: fixed;
    top: 0;
    left: 0;
    margin-left: calc(100vw - 95%);
    margin-top: 1.5rem;
    width: 90vw;
    height: 95vh;
    transform: translateX(0px);
    background: rgba(242, 246, 255, 0.95);
    //transition: transform 0.22s ease-in;
    z-index: 90;
    border-radius: 28px;

    ul {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      height: 86%;

      a {
        &:last-of-type {
          margin-top: auto;
        }
      }
    }

    &__controls {
      position: relative;
      justify-content: flex-end;

      display: flex;
      width: 89%;
      margin: 40px auto 0;

      svg {
        width: 1.7rem;
        height: 40px;
        position: relative;
      }
    }

    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 16px auto 0;
      width: 90%;
      height: 4.5rem;
      padding: 10px 30px;
      border-radius: 18px;
      font-size: 1rem;
      font-weight: 500;

      svg {
        width: 1.8rem;
      }
      // border-bottom: 2px solid rgba(10, 13, 31, 0.5);

      // &:last-of-type {
      //   margin-top: auto;
      // }

      &:hover {
        background: rgba(10, 13, 31, 0.15);
      }
      &:active {
        background: rgba(10, 13, 31, 0.25);
      }

      div {
        display: flex;
      }

      p {
        display: block;
        margin-left: 20px;
        text-align: left;
      }
    }
  }

  .Drawer.close {
    transform: translateX(-500px);
  }

  .Backdrop {
    z-index: 50;
    background: rgba(10, 13, 31, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .developing {
    position: relative;
    top: 12vh;
    color: $black;

    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        text-align: center;
        font-size: 2rem;
      }
      svg {
        margin-top: 2rem;
        width: 10rem;
      }
    }

    span {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      font-size: 1.5rem;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10rem auto 0;
      background-color: $black;
      color: $white;
      font-size: 1.2rem;
      border: none;
      border-radius: 18px;
      width: 80%;
      height: 4rem;

      &:hover {
        background: lighten($black, 10%);
      }
    }
  }
}

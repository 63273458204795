$black: #0a0d1f;
$white: #f2f6ff;

$green: #689f63;
$blue: #427bff;
$purple: #680dd4;
$yellow: #f0db4f;

$red: #fc3567;

$container-width: 90%;

$duration: 0.15s;

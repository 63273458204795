.detailed {
  display: flex;
  flex-direction: column;
  // scroll-snap-type: y mandatory;
  // overflow-y: scroll;

  &__title {
    height: 100vh;
  }

  &__numbers {
    height: 100vh;
  }

  &__fag {
    // scroll-snap-align: start;
    height: 100vh;
    h2 {
      color: $black;
      text-align: center;
      margin: 40px 0;
    }
  }

  &__links {
    height: 100vh;
  }

  &__greetings {
    display: flex;
    width: 1450px;
    margin: 130px auto 0;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__description {
    width: 650px;
    font-size: 28px;
    font-weight: 500;
    text-align: justify;
    line-height: 36px;
    color: $white;
  }

  &__numberBlocks {
    color: $black;
    width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &__block {
      margin-top: 130px;
      width: 450px;

      h1 {
        font-size: 96px;
        font-weight: 700;
      }

      p {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  &__fagContainer {
    color: $black;
    min-height: 600px;
    margin: 0 auto;
    width: 1600px;
    display: flex;
    flex-direction: column;

    &:first-of-type {
      border-top: 3px solid $black;
    }

    section {
      transition: padding 0.4s, background 0.4s;
      cursor: pointer;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: start;
      padding: 40px 0;
      max-height: 400px;
      border-bottom: 3px solid $black;

      &:hover {
        padding-left: 2rem;
        background-color: $black;

        span {
          color: $white;
        }
        p {
          color: $white;
        }
      }

      span {
        font-size: 32px;
        font-weight: 600;
      }
    }

    &-answer {
      font-weight: 400;
      padding-right: 2rem;
      padding-top: 50px;
      font-size: 24px;
      position: relative;
    }
  }

  &__linksContainer {
    display: flex;
    justify-content: space-between;
    width: 1600px;
    margin: 60px auto 0;
    padding-top: 80px;
  }

  &__usefulLinks {
    color: $black;
    width: 700px;
    h3 {
      font-size: 40px;
      font-weight: 600;
      padding-bottom: 1rem;
    }

    &-links {
      display: flex;
      flex-direction: column;

      a {
        font-size: 26px;
        font-weight: 500;
        padding-top: 2rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__responsibleLinks {
    color: $black;
    h3 {
      font-size: 40px;
      font-weight: 600;
      padding-bottom: 1rem;
    }

    &-contacts {
      display: flex;
      flex-direction: column;

      p {
        display: flex;
        align-items: center;
        font-size: 26px;
        font-weight: 500;
        padding-top: 2rem;
        cursor: pointer;
        span {
          margin-left: 2rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__socials {
    color: $black;
    display: flex;
    flex-direction: column;
    width: 1600px;
    margin: 120px auto 0;

    h3 {
      font-size: 40px;
      font-weight: 600;
    }

    &-icons {
      margin-top: 4rem;
      display: flex;

      a {
        margin-right: 3rem;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .detailed {
    display: flex;
    flex-direction: column;

    &__title {
      height: 100vh;
    }

    &__numbers {
      height: 100vh;
    }

    &__fag {
      height: 100vh;
      h2 {
        font-size: 36px;
        color: $black;
        text-align: center;
        margin: 40px 0;
      }
    }

    &__links {
      height: 100vh;
    }

    &__greetings {
      display: flex;
      height: 440px;
      width: 1000px;
      margin: 2rem auto 0;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      svg {
        width: 30rem;
      }
    }

    &__description {
      width: 460px;
      font-size: 19px;
      font-weight: 500;
      text-align: justify;
      line-height: 30px;
      color: $white;
    }

    &__numberBlocks {
      color: $black;
      width: 1040px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 70px;

      div {
        margin-top: 100px;
        width: 300px;

        h1 {
          font-size: 56px;
          font-weight: 700;
        }
        p {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    &__fagContainer {
      color: $black;
      min-height: 400px;
      margin: 0 auto;
      width: 1040px;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;

      &:first-of-type {
        border-top: 3px solid $black;
      }

      section {
        transition: padding 0.4s, background 0.4s;
        cursor: pointer;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: start;
        padding: 26px 0;
        max-height: 400px;
        border-bottom: 3px solid $black;

        &:hover {
          padding-left: 2rem;
          background-color: $black;

          span {
            color: $white;
          }
          p {
            color: $white;
          }
        }

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }

      &-answer {
        font-weight: 400;
        padding-right: 2rem;
        padding-top: 50px;
        font-size: 14px;
        position: relative;
      }
    }

    &__linksContainer {
      display: flex;
      justify-content: space-between;
      width: 1040px;
      margin: 60px auto 0;
      padding-top: 40px;
    }

    &__usefulLinks {
      color: $black;
      width: 500px;
      h3 {
        font-size: 30px;
        font-weight: 600;
        padding-bottom: 1rem;
      }

      &-links {
        display: flex;
        flex-direction: column;

        a {
          font-size: 18px;
          font-weight: 500;
          padding-top: 2rem;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__responsibleLinks {
      color: $black;
      width: 500px;

      h3 {
        font-size: 30px;
        font-weight: 600;
        padding-bottom: 1rem;
      }

      &-contacts {
        display: flex;
        flex-direction: column;
        svg {
          width: 40px;
        }
        p {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          padding-top: 2rem;
          cursor: pointer;
          span {
            margin-left: 1.5rem;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__socials {
      color: $black;
      display: flex;
      flex-direction: column;
      width: 1040px;
      margin: 60px auto 0;

      h3 {
        font-size: 30px;
        font-weight: 600;
      }

      &-icons {
        margin-top: 1rem;
        display: flex;

        a {
          margin-right: 2rem;
          transition: all 0.2s ease-in-out;

          svg {
            width: 5rem;
          }
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .detailed {
    display: flex;
    flex-direction: column;

    &__title {
      height: 100vh;
    }

    &__numbers {
      height: 150vh;
    }

    &__fag {
      height: 100vh;
      h2 {
        font-size: 32px;
        color: $black;
        text-align: center;
        margin: 40px 0;
      }
    }

    &__links {
      height: 200vh;
    }

    &__greetings {
      display: flex;
      width: 90vw;
      margin: 2rem auto 0;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      svg {
        width: 76vw;
        height: 100%;
      }
    }

    &__description {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 80vw;
      font-size: 0.9rem;
      font-weight: 600;
      text-align: justify;
      line-height: 140%;
      color: $black;
    }

    &__numberBlocks {
      color: $black;
      width: 90vw;
      margin: 1rem 5vw 5rem 16vw;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-around;
      align-items: start;
      align-content: center;

      .numbers__block {
        margin-top: 4rem;
        width: 74vw;

        h1 {
          font-size: 60px;
          font-weight: 700;
        }

        p {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    // &__numberBlocks {
    //   color: $black;
    //   width: 90vw;
    //   margin: 1rem 2vw 3rem 8vw;
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: space-around;
    //   align-items: start;
    //   align-content: center;

    //   .numbers__block {
    //     margin-top: 4rem;

    //     width: 42vw;

    //     h1 {
    //       font-size: 32px;
    //       font-weight: 700;
    //     }

    //     p {
    //       font-size: 12px;
    //       font-weight: 500;
    //     }
    //   }
    // }

    &__fagContainer {
      color: $black;
      min-height: 600px;
      margin: 0 auto;
      width: 90vw;
      display: flex;
      flex-direction: column;

      &:first-of-type {
        border-top: 3px solid $black;
      }

      section {
        transition: padding 0.4s, background 0.4s;
        cursor: pointer;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: start;
        padding: 2rem 0;
        max-height: 50vh;
        border-bottom: 3px solid $black;

        &:hover {
          padding-left: 2rem;
          background-color: $black;

          span {
            color: $white;
          }
          p {
            color: $white;
          }
        }

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }

      &-answer {
        font-weight: 400;
        padding-right: 2rem;
        padding-top: 50px;
        font-size: 14px;
        position: relative;
        text-align: justify;
      }
    }

    &__linksContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 90vw;
      margin: 60px auto 0;
      padding-top: 0px;
    }

    &__usefulLinks {
      color: $black;
      width: 100%;
      h3 {
        font-size: 28px;
        text-align: center;
        font-weight: 600;
        padding-bottom: 1rem;
      }

      &-links {
        display: flex;
        flex-direction: column;

        a {
          font-size: 16px;
          font-weight: 500;
          padding-top: 2rem;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__responsibleLinks {
      margin-top: 3rem;
      color: $black;
      h3 {
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        padding-bottom: 1rem;
      }

      &-contacts {
        display: flex;
        flex-direction: column;

        svg {
          width: 2.5rem;
        }

        p {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          padding-top: 2rem;
          cursor: pointer;
          span {
            margin-left: 2rem;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__socials {
      color: $black;
      display: flex;
      flex-direction: column;
      width: 90vw;
      margin: 3rem auto 0;

      h3 {
        text-align: center;
        font-size: 28px;
        font-weight: 600;
      }

      &-icons {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;

        width: 90vw;

        a {
          width: 50%;

          margin-right: 0;
          margin-bottom: 1rem;
          transition: all 0.2s ease-in-out;
          text-align: center;
          &:hover {
            transform: scale(1.1);
          }
          svg {
            width: 6rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 376px) and (-webkit-min-device-pixel-ratio: 2) {
  .detailed {
    display: flex;
    flex-direction: column;

    &__title {
      height: 100vh;
    }

    &__numbers {
      height: 194vh;
    }

    &__fag {
      height: 120vh;
      h2 {
        font-size: 32px;
        color: $black;
        text-align: center;
        margin: 40px 0;
      }
    }

    &__links {
      height: 200vh;
    }

    &__greetings {
      display: flex;
      width: 90vw;
      margin: 2rem auto 0;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      svg {
        width: 70vw;
        height: 100%;
      }
    }

    &__description {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 80vw;
      font-size: 0.8rem;
      font-weight: 600;
      text-align: justify;
      line-height: 140%;
      color: $black;
    }
  }
}
